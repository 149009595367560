import React from 'react';
import { default as MainLogo } from '$icons/logo.svg';
import { Link } from '~/shared/components';
import { StyledLogo } from './styled';

export type LogoProps = {
    /**
     * pass SVG Logo node example: <Logo children={<BasketIcon/>} description={'Go to basket'} />
     */
    children?: React.ReactNode;

    /**
     * Description of what the logo is and where it potentially leads to,
     * this is required for screen readers
     */
    description?: string;
};

export const Logo = ({ children = <MainLogo />, description }: LogoProps) => {
    return (
        <Link href="/" aria-label={description ?? 'Imerco'}>
            <StyledLogo>{children}</StyledLogo>
        </Link>
    );
};
