'use client';

import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';

// If needed variations such as size should be added here
export const StyledLogo = styled.div({
    svg: {
        height: 24,
        width: 152,
    },

    [breakpoints.xs]: {
        svg: {
            height: 16,
            width: 101,
        },
    },
});
