import React from 'react';
import { FooterIcon, Link as LinkType, RichText } from '~/lib/data-contract';
import { MaxWidth } from '$shared/components/MaxWidth';
import { Gutter } from '~/shared/components/Gutter';
import { Link } from '~/shared/components/Link';
import { Icons } from './components/Icons';
import {
    StyledMeta,
    MetaContainer,
    StyledSVGContainer,
    RateContainer,
    TrustPilotStarContainer,
    TrustPilotStar,
    TrustPilot,
} from './styled';

type MetaAreaProps = {
    paymentIcons?: FooterIcon[];
    trustpilotLink?: LinkType;
    rate?: RichText;
};

export const MetaArea = ({ paymentIcons, trustpilotLink, rate }: MetaAreaProps) => {
    return (
        <MetaContainer>
            <MaxWidth>
                <Gutter gutterSize="medium">
                    <StyledMeta>
                        {trustpilotLink && (
                            <Link href={trustpilotLink.url} {...trustpilotLink}>
                                <StyledSVGContainer>
                                    <TrustPilotStarContainer>
                                        <TrustPilotStar />
                                        <TrustPilotStar />
                                        <TrustPilotStar />
                                        <TrustPilotStar />
                                        <TrustPilotStar />
                                    </TrustPilotStarContainer>
                                    <RateContainer
                                        dangerouslySetInnerHTML={{ __html: rate as string }}
                                    />
                                    <TrustPilot />
                                </StyledSVGContainer>
                            </Link>
                        )}
                        {paymentIcons?.length ? <Icons icons={paymentIcons} /> : null}
                    </StyledMeta>
                </Gutter>
            </MaxWidth>
        </MetaContainer>
    );
};
