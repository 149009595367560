import React from 'react';
import { ImageMedia, RichText } from '~/lib/data-contract';
import { GridItemProps } from '../GridItem';
import { default as MainLogo } from '$icons/logo-white.svg';
import { Logo } from '~/shared/components/Logo';
import { LogoText, StyledFlex } from './styled';

type RichTextAreaProps = GridItemProps & {
    text?: RichText;
    logoImage?: ImageMedia;
};

export const RichTextArea = ({ text, logoImage }: RichTextAreaProps) => {
    return (
        <StyledFlex column>
            {logoImage && (
                <Logo description={logoImage.caption}>
                    <MainLogo />
                </Logo>
            )}
            {text && <LogoText html={text} />}
        </StyledFlex>
    );
};
