import styled from '@emotion/styled';
import { mq } from '~/lib';
import { StyledText } from '~/shared/components';
import Flex from '~/shared/components/Flex';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';
import { StyledResetButton } from '~/shared/components/Button/styled';
type StyledIconButtonProps = {
    active?: boolean;
    mobileOnly?: boolean;
    full?: boolean;
    desktop?: boolean;
    textColor?: string;
};
export const StyledFooterContainer = styled(Flex)(({ theme }) => ({
    [breakpoints.xs]: {
        paddingTop: 0,
    },
    [breakpoints.sm]: {
        paddingTop: theme.space[5],
    },
    [breakpoints.md]: {
        paddingTop: theme.space[5],
    },
}));

export const StyledFooter = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.black,
    alignSelf: 'end',
    paddingBottom: 0,
    width: '100%',
    '&:empty': {
        display: 'none',
    },
}));
export const StyledHeaderOptionText = styled(StyledText)(({ theme }) => ({
    color: theme.colors.headerTextColor,
    fontSize: 12,
    letterSpacing: 0,
}));
export const StyledHeaderSmallIconOptionText = styled.p<{
    desktop?: boolean;
    textColor?: string;
}>(
    ({ theme }) => ({
        padding: `${theme.space[1]}`,
        ...theme.mixins.useTextStyle('small'),

        [breakpoints.xs]: {
            display: 'flex',
            padding: `0 0 0 ${theme.space[2]}`,
        },
        '&:hover': {
            textDecoration: 'none',
        },
    }),
    ifProp('textColor', ({ textColor }) => ({
        color: textColor,
    }))
);
export const StyledIconButton = styled(StyledResetButton)<StyledIconButtonProps>(
    ({ theme }) => ({
        display: 'block',
        padding: `${theme.space[4]} ${theme.space[2]}`,
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colors.grey30,
        },

        '&:active': {
            backgroundColor: theme.colors.grey50,
        },
        svg: {
            height: 24,
            width: 24,
        },
    }),
    ifProp('active', ({ theme }) => ({
        backgroundColor: theme.colors.grey30,
        '&:hover': {
            backgroundColor: theme.colors.grey40,
        },
    })),
    ifProp('mobileOnly', {
        [breakpoints.sm]: {
            display: 'none',
        },
    })
);
export const StyledSmallIconButton = styled.div<StyledIconButtonProps>(
    ({ theme, textColor }) => ({
        display: 'flex',
        flexDirection: 'row',
        '&:hover': {
            backgroundColor: 'unset',
            textDecoration: 'none',
        },
        svg: {
            width: 24,
            height: 24,
            '& path': {
                fill: textColor,
            },
        },
        object: {
            width: 24,
            height: 24,
        },
        [breakpoints.xs]: {
            display: 'flex',
            margin: 'auto',
            flexDirection: 'column',
            alignItems: 'center',
            flexShrink: 'unset',
            padding: `${theme.space[2]} ${theme.space[1]}`,
        },
        [breakpoints.sm]: {
            display: 'flex',
            margin: 'auto',
            flexDirection: 'row',
            flexShrink: 'unset',
            padding: `${theme.space[1]} 0 ${theme.space[1]} 0`,
        },
        [breakpoints.md]: {
            flexDirection: 'row',
            margin: `unset`,
            padding: `${theme.space[1]} ${theme.space[2]} ${theme.space[1]} ${theme.space[2]}`,
            '&:hover': {
                backgroundColor: theme.colors.grey30,
            },
        },
    }),
    ifProp('textColor', ({ textColor }) => ({
        color: textColor,
        svg: {
            '& path': {
                fill: textColor,
            },
        },
    }))
);
export const StyledGrid = styled.div({
    display: 'grid',
    gridAutoRows: 'max-content',
    gridTemplateAreas: `"richtext"
                        "icons"
                        "newsletter"`,
    '&:empty': {
        display: 'none',
    },
    [breakpoints.xs]: {
        justifyContent: 'space-between',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateAreas: `"richtext"
                            "newsletter"
                            "icons"`,
    },
    [breakpoints.sm]: {
        justifyContent: 'space-between',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateAreas: `"richtext richtext icons" 
                            "newsletter . ."`,
    },
    [breakpoints.lg]: {
        justifyContent: 'space-between',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateAreas: `"richtext icons newsletter"`,
    },
});

export const StyledFlex = styled(Flex)(() => ({
    justifyContent: 'center',
    [breakpoints.sm]: {
        justifyContent: 'end',
    },
    [breakpoints.lg]: {
        justifyContent: 'center',
    },
}));

export const MobileIconContainer = styled.div({
    display: 'none',
    [mq(0, 'md')]: {
        display: 'block',
    },
});

export const DesktopIconContainer = styled.div({
    display: 'none',
    [mq('md')]: {
        display: 'block',
    },
});
