/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { StyledHeaderSmallIconOptionText, StyledSmallIconButton } from '../styled';
import { ImageMedia, UspItem } from '~/lib';
import { Image, Link } from '~/shared/components';

type ImageProps = {
    image: ImageMedia;
    title?: string;
    height?: number;
    width?: number;
    color?: string;
};
const UspImage = ({ image, title, height, width, color }: ImageProps) => (
    <Image
        src={image.src}
        alt={image.alt || image.name}
        title={title}
        loading="lazy"
        width={width || 35}
        height={height || 20}
        layout="intrinsic"
        color={color}
        disableSkeleton
    />
);
type Props = { desktop?: boolean; textColor?: string; section: UspItem };
export const UspMetaMenuItem = (props: Props) => {
    const { desktop, textColor, section } = props;

    const StyledSmallIconButtonElement = () => (
        <StyledSmallIconButton textColor={textColor}>
            <UspImage
                image={section.image as ImageMedia}
                color={textColor as string}
                width={16}
                height={16}
            />

            <StyledHeaderSmallIconOptionText desktop={desktop} textColor={textColor}>
                {section.headline}
            </StyledHeaderSmallIconOptionText>
        </StyledSmallIconButton>
    );
    return (
        <>
            {section.link ? (
                <Link noHover href={section.link.url} {...section.link}>
                    <StyledSmallIconButtonElement />
                </Link>
            ) : (
                <StyledSmallIconButtonElement />
            )}
        </>
    );
};
