import { Link, StyledAccordionItem } from '$shared/components';

import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';

export const StyledMobileMenu = styled.div({
    display: 'grid',
    [breakpoints.sm]: {
        display: 'none',
    },
});

export const StyledDesktopMenu = styled.div(({ theme }) => ({
    display: 'none',
    gap: theme.space['4'],
    [breakpoints.sm]: {
        display: 'grid',
    },
}));

export const StyledMenuContainer = styled.div(({ theme }) => ({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr',
    gridTemplateRow: 'min-content',
    gridAutoRows: 'max-content',
    paddingBottom: theme.space[3],

    [breakpoints.sm]: {
        gridAutoColumns: '1fr',
        gridAutoFlow: 'column',
        columnGap: theme.space[2],
        paddingBottom: theme.space[5],
    },
    [breakpoints.md]: {
        gridAutoColumns: '1fr',
        gridAutoFlow: 'column',
        columnGap: theme.space[3],
        paddingBottom: theme.space[5],
    },
}));

export const StyledMenuGroup = styled.div({
    display: 'block',
    gridAutoFlow: 'row',
    gridTemplateColumns: 'max-content',
    [breakpoints.md]: {
        display: 'grid',
    },
    [`& + & ${StyledAccordionItem}`]: {
        borderTop: 0,
    },
});

export const StyledMenuItem = styled(Link)(({ theme, color }) => ({
    color: color,
    fontSize: theme.fontSizes.xs,
}));

export const StyledMenuItems = styled.ul(({ theme }) => ({
    display: 'grid',
    gap: theme.space['4'],
}));
