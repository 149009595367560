import { useCallback, useEffect, useRef } from 'react';
import { useEvents } from '../hooks/useEvents';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { useRouter } from 'next/router';
import type { Frame } from '../utils';
import type { Pages } from '~/lib';
import CryptoJS from 'crypto-js';

type Props = {
    frame: Frame;
    page: Pages;
};

const isServer = typeof window === 'undefined';
const pagesExludedFromPageView = ['checkout'];

export function useGoogleTagManagerScript({ frame, page }: Props) {
    if (isServer) return null;

    const isInitialized = useRef(false);
    const router = useRouter();
    const events = useEvents(frame);
    const customer = useCustomer();
    const url = useRef<string>(window.location.pathname);
    const previousUrl = useRef<string>();

    const handleUrlChange = useCallback(
        (newUrl: string) => {
            previousUrl.current = url.current;
            url.current = newUrl;
        },
        [url.current, previousUrl.current]
    );

    async function handlePageView() {
        const sha256email = customer?.email
            ? CryptoJS.SHA256(customer?.email).toString()
            : undefined;
        // TEMP: Remove when we have a proper solution for this
        if (pagesExludedFromPageView.includes(url.current)) return;

        if (previousUrl.current !== url.current) {
            events.pageView({
                page,
                loggedIn: !customer.isGuest,
                customerId: sha256email,
            });
            previousUrl.current = url.current;
        }
    }

    useEffect(() => {
        router.events.on('routeChangeComplete', handleUrlChange);

        return () => {
            router.events.off('routeChangeComplete', handleUrlChange);
        };
    }, [router.events]);

    useEffect(() => {
        if (!customer.isInitialized || isInitialized.current || events.isReady) return;

        events.initialize(customer.customerId, customer.c_imercoCustomerNo);
    }, [customer.customerId, customer.c_imercoCustomerNo, customer.isInitialized, events.isReady]);

    useEffect(() => {
        if (!events.isReady) return;

        handlePageView();

        if (events.hasQueue) {
            events.runQueue();
        }
    }, [events.isReady, url.current, events.hasQueue]);
}
