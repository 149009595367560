'use client';

import styled from '@emotion/styled';
import { GridItemProps } from './GridItem';

export const StyledGridItem = styled.section<GridItemProps>(({ theme, area }) => ({
    gridArea: area,
    display: 'grid',
    gap: theme.space[3],
    gridAutoRows: 'max-content',
}));
