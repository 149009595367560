import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useStorePath = () => {
    const router = useRouter();

    useEffect(() => {
        const storage = globalThis?.sessionStorage;
        if (!storage) return;

        // Set the previous path as the current url
        const prevPath = storage.getItem('currentPath');
        if (prevPath !== null) {
            storage.setItem('prevPath', prevPath);
        }

        // Set the current path value by looking at the browser's navigation.
        storage.setItem('currentPath', globalThis.location.pathname);
    }, [router.asPath]);
};
