'use client';
import React from 'react';
import { FooterIcon, ImageMedia } from '~/lib/data-contract';
import { Image, Link } from '~/shared/components';
import { StyledImageContainer } from '../styled';

const CustomImage = ({
    image,
    title,
    index,
    height,
    width,
}: {
    image: ImageMedia;
    title?: string;
    index: number;
    height?: number;
    width?: number;
}) => (
    <Image
        src={image.src}
        key={image.src + index}
        title={title}
        loading="lazy"
        width={width || 35}
        height={height || 20}
        layout="intrinsic"
        disableSkeleton
    />
);

export const Icons = ({
    icons,
    height,
    width,
}: {
    icons: FooterIcon[];
    height?: number;
    width?: number;
}) => {
    return (
        <StyledImageContainer>
            {icons.map(({ image, title, link }, index) => (
                <React.Fragment key={index}>
                    {link && (
                        <Link href={link.url} {...link}>
                            <CustomImage
                                image={image}
                                height={height}
                                width={width}
                                title={title}
                                index={index}
                            />
                        </Link>
                    )}
                    {!link && (
                        <CustomImage
                            image={image}
                            height={height}
                            width={width}
                            title={title}
                            index={index}
                        />
                    )}
                </React.Fragment>
            ))}
        </StyledImageContainer>
    );
};
