import { AccordionItem } from '$shared/components';
import React, { memo } from 'react';
import {
    StyledDesktopMenu,
    StyledMenuGroup,
    StyledMenuItem,
    StyledMenuItems,
    StyledMobileMenu,
} from '../styled';
import { MenuItemProps } from '..';
import { default as Chevron } from '$icons/chevron-up.svg';
import { default as ChevronDown } from '$icons/chevron-down.svg';
import styled from '@emotion/styled';
import { theme } from '~/theme';
import { mq } from '~/lib';
import { breakpoints } from '~/theme/breakpoints';

export const MenuItem = memo(({ menuItem }: MenuItemProps) => {
    const { header, children } = menuItem;
    const validChildren = children ? children.filter((node) => node.link.url) : [];

    const headerComponent = <HeaderText children={header} />;

    return (
        <StyledMenuGroup>
            <StyledMobileMenu>
                <AccordionItem header={headerComponent} icon={icon}>
                    <StyledMenuItems>
                        {validChildren.map(({ link }) => (
                            <li key={[link.id, link?.url, link.text].join('')}>
                                <StyledMenuItem
                                    href={link.url}
                                    target={link.target}
                                    title={link.title}
                                    children={link.text}
                                    prefetch={false}
                                    color={theme.colors.white}
                                />
                            </li>
                        ))}
                    </StyledMenuItems>
                </AccordionItem>
            </StyledMobileMenu>
            <StyledDesktopMenu>
                {headerComponent}
                <StyledMenuItems>
                    {validChildren.map(({ link }) => (
                        <li key={[link.id, link?.url, link.text].join('')}>
                            <StyledMenuItem
                                href={link.url}
                                target={link.target}
                                title={link.title}
                                children={link.text}
                                prefetch={false}
                                color={theme.colors.white}
                            />
                        </li>
                    ))}
                </StyledMenuItems>
            </StyledDesktopMenu>
        </StyledMenuGroup>
    );
});

const icon = ({ isOpen }: { isOpen: boolean }) => {
    return <ColoredIcon>{isOpen ? <Chevron /> : <ChevronDown />}</ColoredIcon>;
};

const ColoredIcon = styled.div(({ theme }) => ({
    display: 'flex',
    color: theme.colors.white,
}));

const HeaderText = styled.h4(({ theme }) => ({
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: '22px',
    fontWeight: 'normal',
    letterSpacing: 0,

    [breakpoints.xs]: {
        fontSize: 15,
        lineHeight: '20px',
    },

    [mq('xs', 'md')]: {
        fontSize: 15,
        lineHeight: '20px',
    },
}));
